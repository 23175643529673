import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import SidebarSolicitante from "../../components/sidebar_solicitante/Sidebar_Solicitante";
import InputMask from "react-input-mask";
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';


Axios.defaults.withCredentials = true;

const TeleAssAgendadas = (props) => {
    const location = useLocation();
    let history = useHistory();
    const [cpf, setCpf] = useState(location.state.detail);
    const [lista, setLista] = useState([]);
    const [farmaceuticos, setFarmaceuticos] = useState({});
    const [usuario, setUsuario] = useState({});

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const year = String(date.getFullYear()).slice(-2);
    
        return `${day}/${month}/${year}`;
    };
    
    useEffect(() => {
        const loginAndFetchData = async () => {
          try {
            // Primeira requisição: login
            const loginResponse = await Axios.get("http://127.0.0.1:3002/login", {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
              },
            });
      
            if (loginResponse.data.loggedIn === true) {
              setUsuario(loginResponse.data.user[0]);
              setCpf(loginResponse.data.user[0].CPF)
      
              // Segunda requisição: buscar TeleAssistência
              const teleAssistenciaResponse = await Axios.post(
                "http://127.0.0.1:3002/retornaTeleAssistenciaDoPaciente",
                { cpf: cpf }
              );
      
              if (teleAssistenciaResponse.data.message == 0) {
                alert("Erro ao carregar dados");
              } else {
                setLista(teleAssistenciaResponse.data);
      
                // Iterando para buscar dados de farmacêuticos
                teleAssistenciaResponse.data.forEach(async (item) => {
                  const usuarioResponse = await Axios.post(
                    "http://127.0.0.1:3002/buscaInfosFarmaceutico",
                    { cpf: item.cpf_farmaceutico }
                  );
      
                  if (usuarioResponse.data.message == 0) {
                    alert("Erro ao carregar dados do farmacêutico");
                  } else {
                    setFarmaceuticos((prevFarmaceuticos) => ({
                      ...prevFarmaceuticos,
                      [item.cpf_farmaceutico]: usuarioResponse.data[0],
                    }));
                  }
                });
              }
            } else {
              history.push("/login");
            }
          } catch (error) {
            console.error("Erro nas requisições", error);
          }
        };
      
        loginAndFetchData();
      }, [cpf, history]);
      

    return (
        <>
            <SidebarSolicitante>
                <div className="titulo">
                    <h3>Teleassistências agendadas</h3>
                </div>
                <table className="content-table">
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Farmacêutico</th>
                            <th>Chamada de Vídeo</th>
                        </tr>
                    </thead>
                    <tbody align="justify">
                        {lista.map((item) => (
                            <tr key={item.id}>
                                <td>{formatDate(item.data_inicio)}</td>
                                <td>{farmaceuticos[item.cpf_farmaceutico]?.nome}</td>
                                <td>
                                    {item.link_paciente !== 'pending' 
                                    ? <a href={item.link_paciente} target="_blank" rel="noopener noreferrer"> Link da Sala </a>
                                    : <><b>Aguardando Link... </b> 
                                    <Tooltip 
                                    className="videourl_tooltip"
                                    title="Tudo certo! O link será gerado automaticamente no horário do atendimento."
                                    placement="bottom"
                                    arrow
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          fontSize: '0.8rem',
                                        }
                                      }
                                    }}
                                    ><HelpIcon/></Tooltip></>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </SidebarSolicitante>
        </>
    );
};

export default TeleAssAgendadas;
