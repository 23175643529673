import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import { formatDate } from "../../utils/date";

function HistoricoPagamento() {
    Axios.defaults.withCredentials = true;
    const location = useLocation();
    const [lista, setLista] = useState([]);
    const [id, setId] = useState(location.state.detail);

    return (
        <SidebarAdministrativo>
            <div className="titulo">
                <h3>HISTÓRICO DE PAGAMENTOS</h3>
            </div>
        </SidebarAdministrativo>
    );
}
export default HistoricoPagamento;
