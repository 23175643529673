import React, { useState, useEffect } from "react";
import SidebarSolicitante from '../../components/sidebar_solicitante/Sidebar_Solicitante';
import InputMask from "react-input-mask";
import Axios from "axios";
import { useHistory } from "react-router-dom";

const PagarTeleAss = (props) => {

    Axios.defaults.withCredentials = true;
    const [cardNumber, setNumCartao] = useState('');
    const [nomeTitular, setNomeTitular] = useState('');
    const [dataExp, setDataExp] = useState('');
    const [cvv, setCVV] = useState('');
    const [username, setUsuario] = useState({});
    const [usernamePagaMe, setUsuarioPagaMe] = useState({});
    let history = useHistory();

    useEffect(() => {
        Axios.defaults.withCredentials = true;
        Axios.get("http://127.0.0.1:3002/login", {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }).then((response) => {
          if (response.data.loggedIn === true) {
            setUsuario(response.data.user[0]);
          }
        });
      }, []);

      const handlePagaMe=() =>{
        Axios.defaults.withCredentials = true;
        Axios.post("http://127.0.0.1:3002/api/paciente/cadastrar",{
            headers: {
                "Access-Allow-Control-Origin": "http://localhost:3000",
              },
                name: username.nome,
                email: username.email,
                document_number: username.cpf,
                type: "individual",
                country: "br",
                phone_number: username.telefone,
            }).then((res) => {
            if (res.data.message === "0") {
                alert("Erro ao carregar dados do paciente");
            } else {
                setUsuarioPagaMe(res.data[0]);
            }
        }).catch((error) => {
            console.error("Erro ao cadastrar paciente:", error);
        });
        
      }
    const getCurrentDate = (separator = '') => {
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${year}${separator}${month < 10 ? `0${month}` : month}${separator}${date < 10 ? `0${date}` : date}`;
    };

    const register = () => {
        console.log('Número do Cartão:', cardNumber);
        console.log('Nome do Titular:', nomeTitular);
        console.log('Data de Expiração:', dataExp);
        console.log('CVV:', cvv);
        // Lógica de pagamento aqui
        history.push({
            pathname: "/solicitante/success",
                state: { detail: [username.cpf] },
        })
    };

    return (
        <>
            <SidebarSolicitante>
                <div className="titulo">
                    <h1>Marcar Teleassistência</h1>
                    <br />
                    <h4>Para concluir o agendamento, informe seus dados de pagamento.</h4>
                </div>
                <div className="div_teste">
                    <div className="div2">
                        <div className="item">
                            <p>Número do Cartão: </p>
                            <input
                                className="NumCartao"
                                type="number"
                                value={cardNumber}
                                onChange={(e) => setNumCartao(e.target.value)}
                            />
                        </div>
                        <div className="item">
                            <p>Nome do Titular</p>
                            <input
                                type="text"
                                className="nome"
                                value={nomeTitular}
                                onChange={(e) => setNomeTitular(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="div2">
                        <div className="item">
                            <p>Data de Expiração</p>
                            <input
                                type="date"
                                value={dataExp}
                                onChange={(e) => setDataExp(e.target.value)}
                                min="1900-01-01"
                                max={getCurrentDate('-')}
                            />
                        </div>
                        <div className="item">
                            <p>CVV: </p>
                            <InputMask
                                mask="999"
                                className="cvv"
                                value={cvv}
                                onChange={(e) => setCVV(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="div2">
                    <div className="item">
                        <h4>Valor Total: R$ 100,00</h4>
                    </div>
                </div>
                </div>
                <div className="div2">
                    <div className="item">
                        <button onClick={register}>CADASTRAR</button>
                    </div>
                </div>
                <br />
            </SidebarSolicitante>
        </>
    );
};

export default PagarTeleAss;
