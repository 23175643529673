import Axios from "axios";
import React, { useEffect, useState } from "react";
import SidebarFarmaceutico from "../../components/sidebar_farmaceutico/Sidebar_Farmaceutico";
import { useHistory } from "react-router-dom";

const AlterarSenha = (props) => {
  let history = useHistory();
  Axios.defaults.withCredentials = true;

  const [senhaAntiga, setSenhaAntiga] = useState("");
  const [senhaNova, setSenhaNova] = useState("");
  const [cpf, setCPF] = useState("");

  useEffect(() => {
    Axios.get("http://127.0.0.1:3002/loginCPF", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      setCPF(response.data);
    });
  }, []);

  const alteraSenha = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("http://127.0.0.1:3002/alterarSenha", {
      senha_antiga: senhaAntiga,
      nova_senha: senhaNova,
      cpf: cpf,
    }).then((response) => {
      if (response.data.message === "1") {
        alert("Senha alterada com sucesso!");
        history.push("/sair");
      } else {
        if (response.data.message === "0") {
          alert("Erro ao alterar senha!");
        } else {
          alert("Senha antiga incorreta!");
        }
      }
    });
  };

  return (
    <>
      <SidebarFarmaceutico>
        <div className="main__container">
          <div className="form">
            <div className="titulo">
              <h3>ALTERAR SENHA</h3>
            </div>
            <div className="div_teste">
              <div className="item">
                <p>Senha antiga: </p>
                <input
                  type="password"
                  onChange={(e) => {
                    setSenhaAntiga(e.target.value);
                  }}
                ></input>
              </div>
              <div className="item">
                <p>Nova senha: </p>
                <input
                  type="password"
                  onChange={(e) => {
                    setSenhaNova(e.target.value);
                  }}
                ></input>
              </div>
              <div className="div2"></div>
              <div className="div2"></div>
              <div className="div2">
                <div className="item">
                  <p></p>
                  <button className="teste" onClick={alteraSenha}>
                    Confirmar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SidebarFarmaceutico>
    </>
  );
};

export default AlterarSenha;
