import React, { useEffect, useState } from "react";
import SidebarSolicitante from "../../components/sidebar_solicitante/Sidebar_Solicitante";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../utils/date";

const MinhasTelecAndamentoSolicitante = (props) => {
  Axios.defaults.withCredentials = true;

  let history = useHistory();
  const [lista, setLista] = useState([]);
  //const cpf = props.user.cpf;
  const [cpf, setCPF] = useState("");
  const [nomeSolicitante, setNomeSolicitante] = useState("");

  useEffect(() => {
    /*
    Axios.defaults.withCredentials = false;

    Axios.post("http://127.0.0.1:3002/minhasTelecAndamento", {
      cpf: cpf,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response);
      setLista(response.data);
    });
    */
    Axios.defaults.withCredentials = false;

    Axios.get("http://127.0.0.1:3002/login", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        setCPF(response.data.user[0].cpf);
        setNomeSolicitante(response.data.user[0].nome);
        Axios.post("http://127.0.0.1:3002/minhasTelecAndamento", {
          cpf: response.data.user[0].cpf,
        }).then((response) => {
          if (response.data.message) {
            alert(response.data.message);
          }
          console.log(response);
          setLista(response.data);
        });
      }
    });
  }, []);

  const carregaDados = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("http://127.0.0.1:3002/minhasTelecAndamento", {
      cpf: cpf,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response);
      setLista(response.data);
    });
  };
  const abrir = (id) => {
    history.push({
      pathname: "/solicitante/detalhes",
      state: { detail: id },
    });
  };

  const chatTeleconsultoria = (id) => {
    history.push({
      pathname: "/solicitante/chatTeleconsultoria",
      state: { room: id, nomeSolicitante: nomeSolicitante },
    });
  };

  const encerrar = (id) => {
    Axios.defaults.withCredentials = false;

    Axios.post("http://127.0.0.1:3002/encerrarTelec", {
      id: id,
      cpf: cpf,
    }).then((response) => {
      if (response.data.aviso) {
        alert(response.data.aviso);
      } else {
        alert("Você encerrou a teleconsultoria ID: " + id);
        carregaDados();
      }
    });
  };

  return (
    <>
      <SidebarSolicitante>
        <div class="main__container">
          <table class="content-table">
            <thead>
              <tr>
                <th>DATA</th>
                <th>ÁREA INTERESSE</th>
                <th>CHAT</th>
                <th>STATUS</th>
                <th>AÇÃO</th>
              </tr>
            </thead>
            <tbody align="justify">
              {lista.map((item) => (
                <tr>
                  <th>{formatDate(item.data_hora)}</th>
                  <th>{item.area_interesse}</th>
                  <th>
                    {" "}
                    <button
                      style={{ cursor: "pointer" }}
                      id={item.id}
                      onClick={(e) => chatTeleconsultoria(e.target.id)}
                    >
                      Abrir
                    </button>
                  </th>
                  <th>{item.status}</th>
                  <th>
                    <button
                      id={item.id}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        alignContent: "middle",
                      }}
                      onClick={(e) => abrir(e.target.id)}
                    >
                      Abrir
                    </button>
                    <button
                      id={item.id}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => encerrar(e.target.id)}
                    >
                      Encerrar
                    </button>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SidebarSolicitante>
    </>
  );
};

export default MinhasTelecAndamentoSolicitante;
