import React, { useState, useEffect } from "react";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import Axios from "axios";
import InputMask from "react-input-mask";
import { getCurrentDate, getCurrentFullDate } from "../../utils/date";
import { Redirect, useHistory } from "react-router-dom";
import TextField from '@mui/material/TextField';
import SidebarFarmaceutico from "../../components/sidebar_farmaceutico/Sidebar_Farmaceutico";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { makeStyles } from '@mui/styles';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';

dayjs.extend(customParseFormat);

const useStyles = makeStyles({
  hideIcon: {
    '& .MuiIconButton-root': {
      display: 'none',
    },
  },
});

const MarcarTeleA = (props) => {
  const [value, setValue] = React.useState(dayjs('2022-04-07'));

  const [paciente_cpfReg, setPaciente_cpfReg] = useState("");
  const [Farmaceutico, setFarmaceutico] = useState("");
  const [FarmaceuticoID, setFarmaceuticoId] = useState("");
  const [dataInicio, setDataInicio] = useState(moment());
  const [telefone, setTelefone] = useState("");
  const [pront, setPront] = useState("");
  const [nomePaciente, setNomePaciente] = useState("");
  const [usuarioTipo, setUsuarioTipo] = useState(1);
  const [formattedDataInicio, setFormattedDataInicio] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  let history = useHistory();
  const classes = useStyles();

  Axios.defaults.withCredentials = true;




  useEffect(() => {

    Axios.get("http://127.0.0.1:3002/login", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      setFarmaceutico(response.data.user[0]);
      console.log(response.data.user[0])
    }); 

  }, []);





  const criaPront = () => {
    Axios.defaults.withCredentials = false;


   
      if (
        telefone == "" ||
        nomePaciente == "" ||
        paciente_cpfReg == "" ||
        dataInicio == "" ||
        paciente_cpfReg == ""
      ) {
        alert("Preencha todos os campos!");
      } else if (errorMessage) {
        alert(errorMessage);
      } else {
        Axios.post("http://127.0.0.1:3002/criaProntuario", {
          telefone: telefone,
          nome: nomePaciente,
          paciente_cpf: paciente_cpfReg,
        }).then((response) => {
          if (response.data.message != "0") {
            //alert(response.data.insertId)
            setPront(response.data.insertId);
            marcaTeleAss(response.data.insertId);
          } else {
            alert("Erro criar prontuário!");
          }
        });
      }
    
  };

  const marcaTeleAss = (prontId) => {
    Axios.defaults.withCredentials = false;
    var url = "http://localhost:3000/room/" + prontId;
    var link_paciente = "";
    //var link_paciente = "http://localhost:3000/atendimento/" + prontId;
    var linkString = "<a href=" + '"http://localhost:3000/room/' + prontId + '" target="_blank">http://localhost:3000/room/' + prontId + "</a>";
    const formattedDataInicio = moment(dataInicio).format('YYYY-MM-DD HH:mm'); 

    


      Axios.post("http://127.0.0.1:3002/marcaTeleAss", {
        dataInicio: formattedDataInicio,
        link: url,
        link_agenda: linkString,
        link_paciente: link_paciente,
        paciente: paciente_cpfReg,
        pront_id: prontId,
        id_farmaceutico: FarmaceuticoID,
        cpf_farmaceutico: Farmaceutico.cpf,
      }).then((response) => {
        if (response.data.message == "1") {
          alert("Teleassistência marcada com sucesso!");
          marcarAtendimento(prontId, formattedDataInicio);
          history.push("/adm");
        } else {
          alert("Erro ao criar teleassistência!" + formattedDataInicio);
        }
      });

  };

  const marcarAtendimento = (prontId, dataInicio) => {
    Axios.post("http://127.0.0.1:3002/adicionaAtendimento", {
      data: dataInicio,
      id: prontId,
    }).then((response) => {
      if (response.data.message == "0") {
        alert("Erro, por favor tente novamente!");
      }
    });
  };


  /*const handleDateChange = (date) => {
    setDataInicio(date);
    alert("oi"+date);
    if (!isValidDateTime(date)) {
      setErrorMessage("Data e hora inválidas!" + date);
    } else {
      setErrorMessage("");
    }
  };*/

  const handleCpfChange = (e) => {
    if (paciente_cpfReg !== "") {

      Axios.post("http://127.0.0.1:3002/buscarUsuario", {
        cpf: paciente_cpfReg,
      }).then((response) => {
        if (response.data.message) {
          alert(response.data.message);
        }
        console.log(response);
        if (response.data[0]) {
          setNomePaciente(response.data[0].nome);
          setTelefone(response.data[0].telefone);
          Axios.post("http://127.0.0.1:3002/buscaFarmaceutico", {
            cpf: Farmaceutico.cpf 
          }).then((response) => {
            setFarmaceuticoId(response.data[0].id);
          })
        } else {
          alert("Paciente não encontrado");
          setNomePaciente("");
          setTelefone("");
        }
      });
    }
  }

  const NoIcon = () => null;



  if (usuarioTipo === 1) {
    return (
      <>
        <SidebarFarmaceutico>

          <div className="main__container">
            <div className="form">
              <div className="titulo">
                <h3>MARCAR TELEASSISTÊNCIA</h3>
              </div>
              <div className="div2">
                <div className="item">
                  <p>
                    CPF do paciente:
                  </p>
                  <InputMask
                    mask="999.999.999-99"
                    type="text"
                    onBlur={handleCpfChange}
                    onChange={(e) => setPaciente_cpfReg(e.target.value)}
                    value={paciente_cpfReg}
                  />
                </div>
                <div className="item">
                  <p>

                  </p>
                  <button onClick={handleCpfChange} >Buscar paciente</button>
                </div>
              </div>
              <div className="div_teste">
                <div className="div2">
                  <div className="item">
                    <p>Nome paciente: </p>
                    <input
                      readOnly
                      value={nomePaciente}
                      className="readonly-input"
                    ></input>
                  </div>
                  <div className="item">
                    <p>Telefone: </p>
                    <InputMask
                      mask="(99) 99999-9999"
                      value={telefone}
                      readOnly
                      className="readonly-input"
                    ></InputMask>
                  </div>
                </div>
                <div className="div2">
                  <div className="item">
                          <p>Data Início</p>
                          <input
                            min="1900-04-01T00:00"
                            max="2050-04-30T23:49"
                            type="datetime-local"
                            value={dataInicio}
                            onChange={(e) => {
                              setDataInicio(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="div2"></div>
                        <div className="div2"></div>
                        <div className="div2"></div>

                </div>

                <div className="div2">
                  <div className="item">
                    <p></p>
                    <button className="teste" onClick={criaPront}>
                      MARCAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SidebarFarmaceutico>
      </>
    );
  } else if (usuarioTipo === 4) {
    return (
      <>
        <SidebarAdministrativo>

          <div className="main__container">
            <div className="form">
              <div className="titulo">
                <h3>MARCAR TELEASSISTÊNCIA</h3>
              </div>
              <div className="div2">
                <div className="item">
                  <p>
                    CPF do paciente:
                  </p>
                  <InputMask
                    mask="999.999.999-99"
                    type="text"
                    onBlur={handleCpfChange}
                    onChange={(e) => setPaciente_cpfReg(e.target.value)}
                    value={paciente_cpfReg}
                  />
                </div>
                <div className="item">
                  <p>

                  </p>
                  <button onClick={handleCpfChange} >Buscar paciente</button>
                </div>
              </div>
              <div className="div_teste">
                <div className="div2">
                  <div className="item">
                    <p>Nome paciente: </p>
                    <input
                      readOnly
                      value={nomePaciente}
                      className="readonly-input"
                    ></input>
                  </div>
                  <div className="item">
                    <p>Telefone: </p>
                    <InputMask
                      mask="(99) 99999-9999"
                      value={telefone}
                      readOnly
                      className="readonly-input"
                    ></InputMask>
                  </div>
                </div>
                <div className="div2">
                  <div class="item">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <p>Data Inicio: </p>
                      <DateTimePicker
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            inputProps={{
                              ...props.inputProps,
                              placeholder: "dd/mm/aaaa,HH:mm"
                            }}
                          />
                        )}
                        value={dataInicio}
                        onChange={(newValue) => { setDataInicio(newValue) }}
                        inputFormat="DD/MM/YYYY,HH:mm"
                        components={{
                          OpenPickerIcon: NoIcon,
                        }}
                        className={classes.hideIcon}
                      />
                    </LocalizationProvider>


                  </div>
                </div>

                <div className="div2">
                  <div className="item">
                    <p></p>
                    <button className="bt-marcar-tele" onClick={criaPront}>
                      MARCAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SidebarAdministrativo>
      </>
    );
  }
};
export default MarcarTeleA;
