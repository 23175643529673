import React, { useState, useEffect } from "react";
import SidebarFarmaceutico from "../../components/sidebar_farmaceutico/Sidebar_Farmaceutico";
import Axios from "axios";
import { getCurrentFullDate } from "../../utils/date";
import { verifyDate } from "../../utils/verifyDate";
import { useHistory } from "react-router-dom";

const MarcarTeleassFarm = (props) => {
  const [teleaID, setTeleaID] = useState("");
  const [listaTeleAss, setListaTeleAss] = useState([]);
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  let history = useHistory();

  Axios.defaults.withCredentials = true;

  const marcarAtendimento = (prontId, dataInicio) => {
    Axios.post("http://127.0.0.1:3002/adicionaAtendimento", {
      data: dataInicio,
      id: prontId,
    }).then((response) => {
      if (response.data.message === "0") {
        alert("Erro, por favor tente novamente!");
      }
    });
  };

  const remarcaTeleA = () => {
    if (
      dataFim === "" ||
      dataInicio === "" ||
      dataInicio === dataFim ||
      teleaID === ""
    ) {
      alert("Por favor, preencha todos os campos!");
    } else {
      const isFound = listaTeleAss.some((element) => {
        if (element.id === teleaID) {
          return true;
        }
        return false;
      });
      if (isFound) {
        if (verifyDate(dataInicio, dataFim)) {
          Axios.defaults.withCredentials = false;

          Axios.post("http://127.0.0.1:3002/remarcaTeleA", {
            teleaID: teleaID,
            tipo: "Acompanhamento",
            dataInicio: dataInicio,
            dataFim: dataFim,
          }).then((response) => {
            if (response.data.message === "1") {
              Axios.post("http://127.0.0.1:3002/retornaProntuarioID", {
                id: teleaID,
              }).then((response) => {
                marcarAtendimento(response.data[0].id, dataInicio);
              });
              alert("Teleassistência remarcada com sucesso!");
              history.push("/adm");
            } else {
              alert("Erro ao remarcar teleassistência!");
            }
          });
        } else {
          alert("A data de início não pode ser menor que a de fim.");
        }
      } else {
        alert("Teleassistência não encontrada!");
      }
    }
  };

  useEffect(() => {
    Axios.get("http://127.0.0.1:3002/login", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        if (response.data.user[0].tipo_usuario === "1") {
          carregaAgendamentosFarmaceutico(response.data.user[0].cpf);
        } else if (response.data.user[0].tipo_usuario === "5") {
          carregaAgendamentosNutricao(response.data.user[0].cpf);
        } else if (response.data.user[0].tipo_usuario === "6") {
          carregaAgendamentosPsicologia(response.data.user[0].cpf);
        } else if (response.data.user[0].tipo_usuario === "7") {
          carregaAgendamentosEducadorFisico(response.data.user[0].cpf);
        }
      }
    });
  }, []);

  const carregaAgendamentosFarmaceutico = (cpf) => {
    Axios.post(
      "http://127.0.0.1:3002/retornaIDNomeTeleAssistenciaFarmaceutico",
      { id: cpf }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaTeleAss(response.data);
    });
  };

  const carregaAgendamentosNutricao = (cpf) => {
    Axios.post("http://127.0.0.1:3002/retornaIDNomeTeleAssistenciaNutricao", {
      id: cpf,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaTeleAss(response.data);
    });
  };

  const carregaAgendamentosEducadorFisico = (cpf) => {
    Axios.post(
      "http://127.0.0.1:3002/retornaIDNomeTeleAssistenciaEducadorFisico",
      { id: cpf }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaTeleAss(response.data);
    });
  };

  const carregaAgendamentosPsicologia = (cpf) => {
    Axios.post("http://127.0.0.1:3002/retornaIDNomeTeleAssistenciaPsicologia", {
      id: cpf,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaTeleAss(response.data);
    });
  };

  return (
    <>
      <SidebarFarmaceutico>
        <div className="main__container">
          <div className="form">
            <div className="titulo">
              <h3>REMARCAR TELEASSISTÊNCIA</h3>
            </div>
            <div className="div_teste">
              <div className="div2">
                <div className="item">
                  <p>ID da Assistência: </p>
                  <input
                    value={teleaID}
                    type="text"
                    onChange={(e) => {
                      setTeleaID(e.target.value);
                    }}
                  ></input>
                </div>
                <div className="item">
                  <p>Selecionar Teleassistência: </p>
                  <select
                    value={teleaID}
                    onChange={(e) => {
                      setTeleaID(e.target.value);
                    }}
                  >
                    <option value="0">-</option>
                    {listaTeleAss.map((option) => (
                      <option value={option.id}>{option.nome}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="div2">
                <div class="item">
                  <p>Data Inicio: </p>
                  <input
                    type="datetime-local"
                    min={getCurrentFullDate()}
                    onChange={(e) => {
                      setDataInicio(e.target.value);
                    }}
                  ></input>
                </div>
                <div className="item">
                  <p>Data Fim: </p>
                  <input
                    type="datetime-local"
                    min={getCurrentFullDate()}
                    onChange={(e) => {
                      setDataFim(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div className="div2">
                <div className="item">
                  <p></p>
                  <button className="teste" onClick={remarcaTeleA}>
                    REMARCAR
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SidebarFarmaceutico>
    </>
  );
};

export default MarcarTeleassFarm;
