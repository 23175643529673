import React, { useEffect, useState } from "react";
import SidebarSolicitante from '../../components/sidebar_solicitante/Sidebar_Solicitante'
import Axios from "axios";
import { Link, useHistory } from "react-router-dom";

const PrincipalSolicitante = (props) => {

    Axios.defaults.withCredentials = true;

    const [username, setUsuario] = useState([]);
    let history = useHistory();
  
    useEffect(() => {
        Axios.defaults.withCredentials = true;
        Axios.get("http://127.0.0.1:3002/login", {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }).then((response) => {
          setUsuario(response.data.user[0])
          console.log(response)
        });
      }, []);

      const handleTeleAssAgenda = () => {
        history.push({
            pathname: "/solicitante/teleAssAgendadas",
            state: { detail: [username.cpf] },
        });
    };

    const handleMarcarTeleAss = () => {
      history.push({
          pathname: "/solicitante/marcarTeleAss",
          state: { detail: [username.cpf] },
      });
  };

    return (
        <>
           <SidebarSolicitante>
                <div className="titulo">
                    <h1>Olá {username.nome}!</h1>
                    <br></br>
                    <h4>O que gostaria de fazer?</h4>
                </div>
                <br></br>
                <div className="form-wrap-solicitante">
                <div class="login100-form validate-form">
                <button
                type="submit"
                className="login100-form-btn"
                style={{ alignSelf: 'center' }}
                onClick={handleMarcarTeleAss}
              >
                Marcar Teleassistência
              </button>

                <button
                type="submit"
                className="login100-form-btn"
                style={{ alignSelf: 'center' }}
                onClick={handleTeleAssAgenda}
              >
                Teleassistências agendadas
              </button>
                </div>
                </div>
           </SidebarSolicitante>
        </>        
    )
}

export default PrincipalSolicitante