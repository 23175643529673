import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import { formatDate } from "../../utils/date";
import InputMask from "react-input-mask";


Axios.defaults.withCredentials = true;

const HistoricoConsulta = (props) => {
    const location = useLocation();
    const [lista, setLista] = useState([]);
    const [status, setStatus] = useState("");
    const [tipo, setTipo] = useState("");
    const [itemDigitado, setItemDigitado] = useState("");
    const [listaHistorico, setListaHistorico] = useState([]);
    const resultadoDaBusca = lista.filter(listVal => listVal.paciente_cpf != null);
    const [id, setId] = useState(location.state.detail);
    const [dataInicio, setDataInicio] = useState("");
    const [dataFim, setDataFim] = useState("");

    useEffect(() => {
        Axios.post("http://127.0.0.1:3002/listaTodasTeleAssPorId", {
            id: id,
        }).then((response) => {
            if (response.data.message == 0) {
                alert("Erro ao carregar dados");
            } else {
                console.log(response);
                setLista(response.data);
                setListaHistorico(response.data)
            }
        });
    }, []);

    const filtros = () => {
        let temp = [...listaHistorico];
        if (tipo != "") {
            const results = temp.filter((item) => {
                return item.tipo === tipo;
            });
            temp = [...results];
        }
        if (status != "") {
            const results = temp.filter((item) => {
                return item.status === status;
            });
            temp = [...results];
        }
        if (dataInicio != "" && dataFim != "") {
            const inicio = new Date(dataInicio).getTime();
            const fim = new Date(dataFim).getTime();
            temp = temp.filter((item) => {
                const dataConsulta = new Date(item.data_inicio).getTime();
                return dataConsulta >= inicio && dataConsulta <= fim;
            });
        }

        setLista(temp);
    };

    return (
        <SidebarAdministrativo>
            <div className="titulo">
                <h3>HISTÓRICO DE CONSULTAS</h3>
            </div>
            <div class="div2">
                <div class="item">
                    <p>CPF do Paciente: </p>
                    <InputMask
                        class="nome"
                        type="text"
                        value={itemDigitado}
                        onChange={(e) => setItemDigitado(e.target.value)}
                        onKeyUp={(e) => setItemDigitado(e.target.value)}
                        placeholder="Digite o CPF do PACIENTE"
                    ></InputMask>
                </div>
            </div>
            <div class="subtitulo2">
                <h3>Filtros </h3>
            </div>
            <div class="div2">
                <div class="item">
                    <p>Status: </p>
                    <select
                        value={status}
                        id="status"
                        name="status"
                        onChange={(e) => {
                            setStatus(e.target.value);
                        }}
                    >
                        <option></option>
                        <option>Aberta</option>
                        <option>Encerrada</option>
                        <option>Acompanhamento</option>
                        <option>Desmarcado</option>
                    </select>
                </div>
                <div class="item">
                    <p>Tipo: </p>
                    <select
                        value={tipo}
                        id="tipo"
                        name="tipo"
                        onChange={(e) => {
                            setTipo(e.target.value);
                        }}
                    >
                        <option></option>
                        <option>Primeira consulta</option>
                        <option>Acompanhamento</option>
                    </select>
                </div>
                <div class="item">
                    <p>Data Início: </p>
                    <input
                        type="date"
                        value={dataInicio}
                        onChange={(e) => setDataInicio(e.target.value)}
                    />
                </div>
                <div class="item">
                    <p>Data Fim: </p>
                    <input
                        type="date"
                        value={dataFim}
                        onChange={(e) => setDataFim(e.target.value)}
                    />
                </div>
                <div class="item">
                    <p></p>
                    <button onClick={filtros}>SELECIONAR</button>
                </div>
            </div>
            <table class="content-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>PACIENTE CPF</th>
                        <th>ID FARMACÊUTICO</th>
                        <th>ID PSICÓLOGO</th>
                        <th>ID NUTRIÇÃO</th>
                        <th>STATUS</th>
                        <th>LINK</th>
                        <th>LINK AGENDA</th>
                        <th>LINK PACIENTE</th>
                        <th>CÓDIGO VERIFICADOR</th>
                        <th>PRONTUÁRIO ID</th>
                        <th>DATA INÍCIO</th>
                        <th>DATA FIM</th>
                        <th>TIPO</th>
                        <th>CPF FARMACÊUTICO</th>
                    </tr>
                </thead>
                <tbody align="justify">
                    {lista.map((item) => (
                        <tr>
                            <th>{item.id}</th>
                            <th>{item.paciente_cpf}</th>
                            <th>{item.id_farmaceutico}</th>
                            <th>{item.id_psicologo}</th>
                            <th>{item.id_nutricao}</th>
                            <th>{item.status}</th>
                            <th><a href={item.link}>Link</a></th>
                            <th><a href={item.link_agenda}>Link</a></th>
                            <th><a href={item.link_paciente}>Link</a></th>
                            <th>{item.codigo_verificador}</th>
                            <th>{item.prontuario_id}</th>
                            <th>{new Date(item.data_inicio).toLocaleString('pt-BR',
                                {
                                    timeZone: 'UTC'
                                }
                            )
                            }</th>

                            <th>{new Date(item.data_fim).toLocaleString('pt-BR',
                                {
                                    timeZone: 'UTC'
                                }
                            )
                            }</th>
                            <th>{item.tipo}</th>
                            <th>{item.cpf_farmaceutico}</th>
                        </tr>
                    ))}
                </tbody>
            </table>
        </SidebarAdministrativo>
    );
};
export default HistoricoConsulta;
