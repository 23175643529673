import React, { useState, useEffect } from "react";
import Axios from "axios";
import "../Farmaceutico/VideoChamada.css"
import SidebarFarmaceutico from "../../components/sidebar_farmaceutico/Sidebar_Farmaceutico";

const VideoChamada = () => {
  const [meetingUrl, setMeetingUrl] = useState("");
  const [error, setError] = useState(null);
  var dateNow = new Date();
  var currentTime = new Date(dateNow.toISOString());
  const [nomeSala, setnomeSala] = useState('farmaceuticoNome-pacientenome-');

  useEffect(() => {
    Axios.post("http://127.0.0.1:3002/create-meeting", {
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000",
      },
    }).then((response) => {
      setMeetingUrl(response.data);
    })
      .catch((error) => {
        setError("Erro ao criar reuniao.");
      });
  }, []);

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <SidebarFarmaceutico>
      <div className="videoChamada">
        {meetingUrl ? (
          <iframe className="videoChamada"
            src={meetingUrl.hostRoomUrl}
            allow="camera; microphone; fullscreen; speaker; display-capture; compute-pressure"
            title="Video Chamada Farmacêutico"
          ></iframe>
        ) : (
          <p>Criando a reunião, por favor, aguarde...</p>
        )}
      </div>
    </SidebarFarmaceutico>
  );
};

export default VideoChamada;
