import {
  Day,
  Inject,
  Month,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Week,
  WorkWeek,
} from "@syncfusion/ej2-react-schedule";
import Axios from "axios";
import React, { useState } from "react";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import { isValidCPF } from "../../utils/valida_cpf";
import InputMask from "react-input-mask";

const BuscaAgenda = (props) => {
  Axios.defaults.withCredentials = true;

  const [data, setData] = useState("");
  const [cpf, setCPF] = useState("");

  const buscarAgenda = () => {
    if (isValidCPF(cpf)) {
      Axios.defaults.withCredentials = false;

      Axios.post("http://127.0.0.1:3002/retornaAgenda", { cpf: cpf }).then(
        (response) => {
          if (response.data.message) {
            alert(response.data.message);
          }

          setData(response.data);
        }
      );
    } else {
      alert("CPF inválido.");
    }
  };

  const fields = {
    id: "id",
    subject: { name: "status", title: "Compromisso", default: "Não Informado" },
    location: { name: "link", title: "Sala", default: "Não Informado" },
    description: { name: "prontuario_id", title: "Responsável" },
    startTime: { name: "data_inicio", title: "Começa" },
    endTime: { name: "data_fim", title: "Termina" },
  };

  return (
    <>
      <SidebarAdministrativo>
        <div className="main__container">
          <div>
            <div class="titulo">
              <h3>BUSCAR AGENDA DE COLABORADOR</h3>
            </div>
            <div class="div_teste">
              <div class="div2">
                <div class="item">
                  <p className="subtitulo">Agenda: </p>
                  <InputMask
                    mask="999.999.999-99"
                    class="nome"
                    onChange={(e) => {
                      setCPF(e.target.value);
                    }}
                    placeholder="Digite o CPF do paciente"
                  ></InputMask>
                </div>
                <div class="item">
                  <p></p>
                  <button onClick={buscarAgenda}>BUSCAR</button>
                </div>
              </div>
            </div>
          </div>
          <ScheduleComponent
            eventSettings={{ dataSource: data, fields: fields }}
          >
            <ViewsDirective>
              <ViewDirective option="Day" />
              <ViewDirective option="Week" />
              <ViewDirective option="Month" />
            </ViewsDirective>
            <Inject services={[Day, Week, WorkWeek, Month]} />
          </ScheduleComponent>
        </div>
      </SidebarAdministrativo>
    </>
  );
};

export default BuscaAgenda;
