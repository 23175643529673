import React from 'react';
import { useHistory } from 'react-router-dom';
import './EscolhaCadastro.css';
import doctorImg from '../../imagens/doctor.png'

const EscolhaCadastro = () => {
  const history = useHistory();

  const handlePacienteClick = () => {
    history.push('paciente/cadastro');
  };

  const handleFarmaceuticoClick = () => {
    history.push('farmaceutico/cadastro');
  };

  return (
    <div className="escolha-cadastro-container">
      <h1>Como você deseja utilizar nossa plataforma?</h1>
      <div className="card-container">
        <div className="card" onClick={handlePacienteClick}>
          <div className="image-placeholder"></div>
          <button>Sou Paciente</button>
        </div>
        <div className="card" onClick={handleFarmaceuticoClick}>
          <div className="image-placeholder-doctor"></div>
          <button>Sou Farmacêutico</button>
        </div>
      </div>
    </div>
  );
};

export default EscolhaCadastro;
