import React, { useEffect, useState } from "react";
import SidebarFarmaceutico from "../../components/sidebar_farmaceutico/Sidebar_Farmaceutico";
import Axios from "axios";
import { Box, useMediaQuery } from "@mui/material";
import TabelaTeleassistenciasFuturas from "../../components/TabelaTeleassistenciasFuturas/TabelaTeleassistenciasFuturas";

const Principal = (props) => {
  const [teleAssitenciasHoje, setTeleAssitenciasHoje] = useState("0");
  const [teleConsultoriasAbertas, setTeleConsultoriasAbertas] = useState("0");

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    Axios.get(
      "http://127.0.0.1:3002/qtdTelecAbertas"
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      setTeleConsultoriasAbertas(response.data[0].quantidade);
    });

    Axios.get("http://127.0.0.1:3002/login", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        if (response.data.user[0].tipo_usuario === "1") {
          carregaAgendamentosFarmaceuticos(response.data.user[0].cpf);
        } else if (response.data.user[0].tipo_usuario === "5") {
          carregaAgendamentosNutricao(response.data.user[0].cpf);
        } else {
          carregaAgendamentosPsicologia(response.data.user[0].cpf);
        }
      }
    });
  }, []);

  const carregaAgendamentosFarmaceuticos = (cpf_origem) => {
    console.log(cpf_origem);
    Axios.post(
      "http://127.0.0.1:3002/qtdTeleAssFarmaceutico",
      {
        id: cpf_origem,
      }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data);
      setTeleAssitenciasHoje(response.data[0].quantidade);
    });
  };

  const carregaAgendamentosNutricao = (cpf_origem) => {
    Axios.post(
      "http://127.0.0.1:3002/qtdTeleAssNutricao",
      {
        id: cpf_origem,
      }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      setTeleAssitenciasHoje(response.data[0].quantidade);
    });
  };

  const carregaAgendamentosPsicologia = (cpf_origem) => {
    Axios.post(
      "http://127.0.0.1:3002/qtdTeleAssPsicologia",
      {
        id: cpf_origem,
      }
    ).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      setTeleAssitenciasHoje(response.data[0].quantidade);
    });
  };

  return (
    <>
      <SidebarFarmaceutico>
        <div className="main__container">
          <div className="main__title">
            <div className="main__greeting">
              <h1>Bem vindo ao dashboard!</h1>
              <p>Setor de atendimento</p>
            </div>
          </div>

          <div className="main__cards" style={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', gap: '1rem' }}>
            <div className="card" style={{ flex: 1 }}>
              <div className="card_inner">
                <p className="text-primary-p">Teleconsultorias Abertas</p>
                <span className="font-bold text-title">
                  {teleConsultoriasAbertas}
                </span>
                <i
                  className="fa fa-user-o fa-2x text-lightblue"
                  aria-hidden="true"
                ></i>
              </div>
            </div>

            <div className="card" style={{ flex: 1 }}>
              <div className="card_inner">
                <p className="text-primary-p">Teleassistências Hoje</p>
                <span className="font-bold text-title">{teleAssitenciasHoje}</span>
                <i className="fa fa-calendar fa-2x text-red" aria-hidden="true"></i>
              </div>
            </div>
          </div>

    

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
                  <Box sx={{ overflowX: 'auto', width: '100%' }}>
                    <TabelaTeleassistenciasFuturas style={{ minWidth: '600px' }} />
                  </Box>
                </Box>

           

        </div>
      </SidebarFarmaceutico>
    </>
  );
};

export default Principal;