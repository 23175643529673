import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import TextField from '@mui/material/TextField'
import { Checkbox } from '@mui/material';
import { getCurrentFullDateComSegundos } from "../../utils/date";

Axios.defaults.withCredentials = true;

const BloqueioFarmaceutico = (props) => {
  const location = useLocation();
  const [lista, setLista] = useState([]);
  const [cpf, setCpf] = useState(location.state.detail);
  const [status, setStatus] = useState([]);
  const [motivo, setMotivo] = useState([]);
  const [mostrarMotivo, setMostrarMotivo] = useState(false);
  let history = useHistory();

  useEffect(() => {
    Axios.post("http://127.0.0.1:3002/status_farmaceutico/", {
      cpf: cpf,
    }).then((response) => {
      if (response.data.message == 0) {
        alert("Erro ao carregar dados");
      } else {
        console.log(response);
        setLista(response.data);
      }
    });
  }, []);

  const handleCheckboxChange = (e) => {
    setMostrarMotivo(e.target.checked);
  };

  const bloqueioOuDesbloqueio = () => {
    Axios.post("http://127.0.0.1:3002/bloquearFarmaceutico/", {
      status: status,
      motivo: motivo,
      codigo_farmaceutico: cpf,
      data_hora: getCurrentFullDateComSegundos(),
    }).then((response) => {
      if (response.data.message == 0) {
        alert("Erro ao bloquear");
      } else {
        history.push("/adm");
        console.log(response);
      }
    });
  }
  const statusAtual = lista.length > 0 ? lista[0].status : "Ativo";
  return (
    <SidebarAdministrativo>
      <div className="titulo">
        <h3>BLOQUEAR/DESBLOQUEAR FARMACÊUTICO</h3>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {statusAtual === "Ativo" ? (
          <h2>Status do Farmacêutico: Ativo &#x2713;</h2>
        ) : (
          <h2>Status do Farmacêutico: Bloqueado &#128711;</h2>

        )}
        <br></br>

        {lista.map((item) => (
          <div key={item.id}>
            <h4>Ação realizada em: {new Date(item.data_hora).toLocaleString('pt-BR',
              { timeZone: 'UTC' })}</h4>
            <br></br>
            <h4>Motivo:</h4>
            <p>{item.motivo}</p>
          </div>
        ))}


        <Checkbox
          checked={mostrarMotivo}
          onChange={handleCheckboxChange}
          color="primary"
          inputProps={{ 'aria-label': 'Mostrar campo de motivo' }}
        />
        {statusAtual === "Ativo" ? (
          <label>Deseja bloquear o farmacêutico?</label>

        ) : (
          <label>Deseja desbloquear o farmacêutico?</label>
        )}


        {(mostrarMotivo &&
          <div className="main__container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="form" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {statusAtual === "Ativo" ? (
                <div className="item" style={{ marginBottom: '10px', width: '100%' }}>
                  <h3>Qual o motivo?</h3>
                  <br />
                  <TextField
                    autoComplete="Motivo"
                    name="Motivo"
                    fullWidth
                    multiline
                    rows={4}
                    onChange={(e) => {
                      setMotivo(e.target.value);
                      setStatus("Bloqueado");
                    }}

                    style={{ marginBottom: '10px' }}
                  />
                </div>
              ) : (
                <div className="item" style={{ marginBottom: '10px', width: '100%' }}>
                  <h3>Qual o motivo?</h3>
                  <br />
                  <TextField
                    autoComplete="Motivo"
                    name="Motivo"
                    fullWidth
                    multiline
                    rows={4}
                    onChange={(e) => {
                      setMotivo(e.target.value);
                      setStatus("Ativo");
                    }}
                    style={{ marginBottom: '10px' }}
                  />
                </div>
              )}

              <button
                type="submit"
                className="login100-form-btn"
                style={{ alignSelf: 'center' }}
                onClick={bloqueioOuDesbloqueio}
              >
                Registrar
              </button>
            </div>
          </div>
        )}
      </div>
    </SidebarAdministrativo>
  );

}
export default BloqueioFarmaceutico;
