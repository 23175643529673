import React, { useState } from "react";
import SidebarFarmaceutico from "../../components/sidebar_farmaceutico/Sidebar_Farmaceutico";
import io from "socket.io-client";
import { useLocation } from "react-router";
import "../Farmaceutico/ChatTeleconsultoria.css";
import Chat from "../../utils/ChatTeleconsultoria";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { Row, Col } from "react-simple-flex-grid";
import "react-simple-flex-grid/lib/main.css";

const ChatTeleconsultoria = () => {
  const socket = io.connect("http://localhost:3000:3001");
  const location = useLocation();
  const room = useState(location.state.room);
  const username = useState(location.state.nomeFarmaceutico);

  return (
    <div>
      <SidebarFarmaceutico>
        <Row gutter={40} align="pace-around">
          <Col span={6}>
            <JitsiMeeting
              domain={"devteste.ga"}
              roomName={room}
              getIFrameRef={(node) => {
                node.style.width = "100%";
                node.style.height = "100%";
              }}
            />
          </Col>
          <Col span={6}>
            <Chat socket={socket} username={username} room={room} />
          </Col>
        </Row>
      </SidebarFarmaceutico>
    </div>
  );
};

export default ChatTeleconsultoria;
