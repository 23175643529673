import React, { useEffect, useState } from "react";
import SidebarFarmaceutico from "../../components/sidebar_farmaceutico/Sidebar_Farmaceutico";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../utils/date";

const TelecAndamentoFarm = (props) => {
  Axios.defaults.withCredentials = true;

  let history = useHistory();
  const [lista, setLista] = useState([]);
  //const cpf = props.user.cpf;
  const [cpf, setCPF] = useState("");
  const [nomeFarmaceutico, setNomeFarmaceutico] = useState("");

  const abrir = (id) => {
    history.push({
      pathname: "/farmaceutico/detalhes",
      state: { detail: id },
    });
  };

  const chatTeleconsultoria = (id) => {
    history.push({
      pathname: "/farmaceutico/chatTeleconsultoria",
      state: { room: id, nomeFarmaceutico: nomeFarmaceutico },
    });
  };

  const responder = (id) => {
    history.push({
      pathname: "/farmaceutico/responderTelec",
      state: { detail: id },
    });
  };

  const encerrar = (id) => {
    Axios.defaults.withCredentials = false;

    Axios.post("http://127.0.0.1:3002/encerrarTelec", {
      id: id,
      cpf: cpf,
    }).then((response) => {
      if (response.data.aviso) {
        alert(response.data.aviso);
      } else {
        alert("Você encerrou a teleconsultoria ID: " + id);
        carregaDados();
      }
    });
  };

  const carregaDados = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("http://127.0.0.1:3002/telecAndamento", { cpf: cpf }).then(
      (response) => {
        if (response.data.message) {
          alert(response.data.message);
        }
        console.log(response);
        setLista(response.data);
      }
    );
  };

  useEffect(() => {
    Axios.defaults.withCredentials = false;
    Axios.get("http://127.0.0.1:3002/login", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        setNomeFarmaceutico(response.data.user[0].nome);
        setCPF(response.data.user[0].cpf);
        Axios.post("http://127.0.0.1:3002/telecAndamento", {
          cpf: response.data.user[0].cpf,
        }).then((response) => {
          if (response.data.message) {
            alert(response.data.message);
          }
          console.log(response);
          setLista(response.data);
        });
      }
    });
  }, []);

  return (
    <>
      <SidebarFarmaceutico>
        <div class="main__container">
          <table class="content-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>SOLICITANTE</th>
                <th>DATA</th>
                <th>CHAT</th>
                <th>ÁREA INTERESSE</th>
                <th>STATUS</th>
                <th>AÇÃO</th>
              </tr>
            </thead>
            <tbody align="justify">
              {lista.map((item) => (
                <tr>
                  <th>{item.id}</th>
                  <th>{item.nome}</th>
                  <th>{formatDate(item.data_hora)}</th>
                  <th>
                    <button
                      style={{ cursor: "pointer" }}
                      id={item.id}
                      onClick={(e) => chatTeleconsultoria(e.target.id)}
                    >
                      Abrir
                    </button>

                  </th>
                  <th>{item.area_interesse}</th>
                  <th>{item.status}</th>
                  <th
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "column",
                      alignContent: "middle",
                    }}
                  >
                    <button
                      style={{ cursor: "pointer" }}
                      id={item.id}
                      onClick={(e) => abrir(e.target.id)}
                    >
                      Abrir
                    </button>
                    <button
                      style={{ cursor: "pointer" }}
                      id={item.id}
                      onClick={(e) => responder(e.target.id)}
                    >
                      Responder
                    </button>
                    <button
                      style={{ cursor: "pointer" }}
                      id={item.id}
                      onClick={(e) => encerrar(e.target.id)}
                    >
                      Encerrar
                    </button>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SidebarFarmaceutico>
    </>
  );
};

export default TelecAndamentoFarm;
