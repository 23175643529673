import React, { useEffect, useState } from "react";
import "./styles.css";
import "./lista.css";
import "./form.css";
import "./tabela.css";
import Axios from "axios";
import { Link } from "react-router-dom";

const SidebarFarmaceutico = (props) => {
  Axios.defaults.withCredentials = true;

  const [username, setUsuario] = useState([]);

  useEffect(() => {
    Axios.defaults.withCredentials = true;
    Axios.get("http://127.0.0.1:3002/login", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        setUsuario(response.data.user[0]);
      }
    });
  }, []);

  return (
    <>
      <div className="div_body">
        <div className="container">
          <nav className="navbar">
            <div className="nav_icon">
              <i className="fa fa-bars" aria-hidden="true"></i>
            </div>
            <div className="navbar__left">
              <a className="active_link" href="/">
                Painel
              </a>
            </div>
            <div className="navbar__right">
              <label>
                Olá, <b>{username.nome}</b>!
              </label>
            </div>
          </nav>
          <main>
            <div className="main__container">{props.children}</div>
          </main>
          <div id="sidebar">
            <div className="sidebar__title">
              <div className="sidebar__img">
                <h1>MYDIGICARE | Portal Farmacêutico</h1>
              </div>
              <i
                className="fa fa-times"
                id="sidebarIcon"
                aria-hidden="true"
              ></i>
            </div>
            <div className="sidebar__menu">
              <div className="sidebar__link">
                <i class="fa fa-solid fa-lock"></i>
                <Link to="/farmaceutico/alterarSenha">Alterar senha</Link>
              </div>
              <br></br>
              <div class="teleassistencia">
                <h2>TELEASSISTÊNCIAS</h2>
                <div className="sidebar__link">
                  <i className="fa fa-search"></i>
                  <Link to="/farmaceutico/buscaTeleass">
                    Buscar Teleassistência
                  </Link>
                </div>
                <div className="sidebar__link">
                  <i className="fa fa-plus"></i>
                  <Link to="/farmaceutico/marcar">
                    Marcar / Remarcar Teleassistência
                  </Link>
                </div>
                <div className="sidebar__link">
                  <i className="fa fa-plus"></i>
                  <Link to="/farmaceutico/marcar">
                    Remarcar Teleassistência
                  </Link>
                </div>
                <div className="sidebar__link">
                  <i className="fa fa-clock-o"></i>
                  <Link to="/farmaceutico/alterarHorarioTeleA">
                    Alterar horário - Teleassistência
                  </Link>
                </div>
                <div className="sidebar__link">
                  <i className="fa fa-minus"></i>
                  <Link to="/farmaceutico/desmarcarTeleassistencia">
                    Desmarcar Teleassistência
                  </Link>
                </div>
              </div>
              <br></br>
              <div className="sidebar__logout">
                <i className="fa fa-power-off"></i>
                <Link to="/sair">Sair</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarFarmaceutico;
