import { Table, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import moment from 'moment';
import 'moment-timezone';

function TabelaTeleassistenciasFuturas() {
  const [teleassistencias, setTeleassistencias] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');

  useEffect(() => {
    Axios.get("http://127.0.0.1:3002/login", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        if (response.data.user[0].tipo_usuario === 1) {
          carregaTelecsSemana(response.data.user[0].cpf);
        }
      }
    });
  }, []);

  const carregaTelecsSemana = (cpf_origem) => {
    Axios.post("http://127.0.0.1:3002/teleAssistenciaSemana", {
      id: cpf_origem,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      setTeleassistencias(response.data);
    });
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedTeleassistencias = teleassistencias.sort((a, b) => {
    if (orderBy === 'data_inicio' || orderBy === 'data_fim') {
      return order === 'asc'
        ? moment(a[orderBy]).diff(moment(b[orderBy]))
        : moment(b[orderBy]).diff(moment(a[orderBy]));
    } else {
      return order === 'asc'
        ? a[orderBy] < b[orderBy] ? -1 : 1
        : a[orderBy] > b[orderBy] ? -1 : 1;
    }
  });

  return (
    <>
      <div className="" style={{ padding: '10px' }}>
        <h1>Teleassistências Futuras <i className="fa fa-calendar" aria-hidden="true"></i></h1>
        <p>Teleassistencias em até 7 dias</p>
        <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="HeadAdesaoMedicamentos" sx={{ backgroundColor: '#EAEAEA' }}>
              <TableRow sx={{ whiteSpace: "nowrap" }}>
                {['id', 'nome', 'status', 'link', 'data_inicio', 'hora_inicio'].map((column) => (
                  <TableCell key={column} align="left">
                    <TableSortLabel
                      active={orderBy === column}
                      direction={orderBy === column ? order : 'asc'}
                      onClick={() => handleRequestSort(column)}
                    >
                      {column.replace('_', ' ').toUpperCase()}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTeleassistencias.map((tele) => {
                const dataInicio = moment.utc(tele.data_inicio);
                const dataFim = moment.utc(tele.data_fim);
                return (
                  <TableRow key={tele.id}>
                    <TableCell component="th" scope="row">{tele.id}</TableCell>
                    <TableCell component="th" scope="row" sx={{ whiteSpace: "nowrap" }}>{tele.nome}</TableCell>
                    <TableCell component="th" scope="row" sx={{ whiteSpace: "nowrap" }}>{tele.status}</TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}><a href={tele.link}>Clique aqui</a></TableCell>
                    <TableCell align="left">{dataInicio.format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="left">{dataInicio.format('HH:mm')}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default TabelaTeleassistenciasFuturas;