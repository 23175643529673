import React, { useState,useEffect } from "react";
import SidebarSolicitante from '../../components/sidebar_solicitante/Sidebar_Solicitante';
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
const MarcarTeleAssSoli = (props) => {

    Axios.defaults.withCredentials = true;

    const [dateTime, setDateTime] = useState("");
    const [username, setUsuario] = useState([]);
    const [prontID, setProntID] = useState(0);
    const [listaFarmaceutico, setListaFarmaceutico] = useState([]);
    const [cpfFarmaceutico, setCpfFarmaceutico] = useState("");
    const [idFarmaceutico, setIdFarmaceutico] = useState(0);
    let history = useHistory();

    useEffect(() => {

      Axios.get("http://127.0.0.1:3002/lista_farmaceuticos").then((response) => {
        if (response.data.message) {
          alert(response.data.message);
        }
        console.log(response.data[0]);
        setListaFarmaceutico(response.data);
      });

        Axios.get("http://127.0.0.1:3002/login", {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }).then((response) => {
          if (response.data.loggedIn === true) {
            setUsuario(response.data.user[0]);
          } else {
            history.push("/login");
          }
        });
      }, [history]);
    const handleDateChange = (event) => {
        setDateTime(event.target.value);
        console.log("Data e hora selecionadas:", event.target.value);
    };
    
    const handleDateTime = () => {
      Axios.post("http://127.0.0.1:3002/criaProntuario", {
        telefone: username.telefone,
        nome: username.nome,
        paciente_cpf: username.CPF,
      }).then((response) => {
        setProntID(response.data.insertId);
        Axios.post("http://127.0.0.1:3002/marcaTeleAss", {
            dataInicio: dateTime,
            link_agenda:'<a href="http://localhost:3000/room/'+response.data.insertId+'" target="_blank">http://localhost:3000/room/'+response.data.insertId+'</a>',
            link_paciente: "pending",
            link: "http://localhost:3000/room/"+response.data.insertId,
            paciente: username,
            pront_id: response.data.insertId,
            cpf_farmaceutico: cpfFarmaceutico,
            id_farmaceutico: idFarmaceutico
          }).then((response) => {
            if (response.data.message == "1") {
             // alert("Teleassistência marcada com sucesso!");
              history.push({
                pathname: "/solicitante/pagarTeleAss",
                state: { detail: [username.cpf] },
            });  
            } else {
              alert("Erro ao criar teleassistência!");
            }
          });
        })
    };


    const handleSelectFarma = (e) => {
      const selectedId = Number(e.target.value);
      const selectedFarmaceutico = listaFarmaceutico.find((option) => option.id === selectedId);
      //teste
        if (selectedFarmaceutico) {
            setCpfFarmaceutico(selectedFarmaceutico.cpf);
            setIdFarmaceutico(selectedFarmaceutico.id);
        }
    }

    return (
        <>
           <SidebarSolicitante>
                <div className="titulo">
                    <h1>Marcar Teleassistência</h1>
                    <br />
                    <h4>Por favor, selecione o farmacêutico de sua preferência, a data e a hora ideal para você</h4>
                </div>
                <br />
                <div className="schedule-select">
                <div className="div2">
                  <div className="item">
                    <p>Farmacêutico: </p>
                    <select onChange={handleSelectFarma}>
                      <option value=""></option>
                      {listaFarmaceutico.map((option) => (
                        <option value={option.id}>{option.nome}</option>
                      ))}
                    </select>
                  </div>
                </div>
                
                <div className="div2">
                <div className="item">
                <div className="input-container">
                    <input
                        type="datetime-local"
                        value={dateTime}
                        onChange={handleDateChange}
                        required
                    />
                </div>
                </div>
                </div>
                

                <button
                type="submit"
                className="login100-form-btn"
                style={{ alignSelf: 'center' }}
                onClick={handleDateTime}
              >
               Continuar para pagamento
              </button>
              </div>

           </SidebarSolicitante>
        </>        
    )
};

export default MarcarTeleAssSoli;
