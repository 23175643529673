import Axios from "axios";
import React, { useEffect, useState } from "react";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";

function ListaFarmaceuticos() {
  Axios.defaults.withCredentials = true;

  const [lista, setLista] = useState([]);

  useEffect(() => {
    Axios.defaults.withCredentials = false;

    Axios.get("http://127.0.0.1:3002/lista_farmaceuticos").then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response);
      setLista(response.data);
    });
  }, []);

  return (
    <SidebarAdministrativo>
      <div className="titulo">
        <h3>LISTAGEM FARMACÊUTICO</h3>
      </div>
      <table class="content-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>NOME</th>
            <th>EMAIL</th>
            <th>ESTADO</th>
            <th>CIDADE</th>
            <th>TELEFONE</th>
            <th>ESPECIALIDADE</th>
            <th>CRF</th>
            <th>CRF-UF</th>
          </tr>
        </thead>
        <tbody align="justify">
          {lista.map((item) => (
            <tr>
              <th>{item.id}</th>
              <th>{item.nome}</th>
              <th>{item.email}</th>
              <th>{item.estado}</th>
              <th>{item.cidade}</th>
              <th>{item.telefone}</th>
              <th>{item.especialidade}</th>
              <th>{item.registro}</th>
              <th>{item.uf}</th>
            </tr>
          ))}
        </tbody>
      </table>
    </SidebarAdministrativo>
  );
}

export default ListaFarmaceuticos;
