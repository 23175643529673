import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./login.css";
import "./main.css";
import "./util.css";
import imageLogo from "../../imagens/logo.svg";

export default function Login() {
  let history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");

  Axios.defaults.withCredentials = true;

  const login = () => {
    Axios.post("http://127.0.0.1:3002/login", {
      withCredentials: true,
      headers: {
        "Access-Allow-Control-Origin":
          "http://localhost:3000",
      },
      username: username,
      password: password,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        setLoginStatus(response.data[0].nome);
        if (
          response.data[0].tipo_usuario == "1" ||
          response.data[0].tipo_usuario == "5" ||
          response.data[0].tipo_usuario == "6" ||
          response.data[0].tipo_usuario == "7"
        ) {
          history.push("/farmaceutico");
        } else if (response.data[0].tipo_usuario == "3") {
          history.push("/solicitante");
        } else if (
          response.data[0].tipo_usuario == "0" ||
          response.data[0].tipo_usuario == "4"
        ) {
          history.push("/adm");
        }
      }
    });
  };

  function cadastro() {
    history.push("/solicitante/cadastro");
  }

  // faz login com o botão enter
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  useEffect(() => {
    Axios.get("http://127.0.0.1:3002/login", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        //console.log(response.data.user);
        //console.log(response.data.user[0].tipo_usuario);
        if (
          response.data.user[0].tipo_usuario == "1" ||
          response.data.user[0].tipo_usuario == "5" ||
          response.data.user[0].tipo_usuario == "6" ||
          response.data.user[0].tipo_usuario == "7"
        ) {
          //console.log("farmaceutico");
          history.push("/farmaceutico");
        } else if (response.data.user[0].tipo_usuario == "3") {
          //console.log("solicitante");
          history.push("/solicitante");
        } else if (
          response.data.user[0].tipo_usuario == "0" ||
          response.data.user[0].tipo_usuario == "4"
        ) {
          //console.log("adm");
          history.push("/adm");
        }
      }
    });
  }, []);
  return (
    <>
      <div class="limiter">
        <div class="container-login100">
          <div class="wrap-login100">
            <div class="login100-pic js-tilt" styles="height: 100%" data-tilt>
              <img src={imageLogo} />
            </div>

            <div class="login100-form validate-form">
              <div class="wrap-input100 validate-input">
                <input
                  class="input100"
                  type="email"
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  placeholder="Email"
                ></input>
              </div>
              <div class="wrap-input100 validate-input">
                <input
                  class="input100"
                  type="password"
                  onKeyPress={handleKeyPress}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder="Senha"
                ></input>
              </div>
              <div class="container-login100-form-btn">
                <button type="submit" onClick={login} class="login100-form-btn">
                  Entrar spark
                </button>
              </div>
              <div class="container-login100-form-btn">
                <button
                  type="submit"
                  onClick={cadastro}
                  class="login100-form-btn"
                >
                  Cadastrar
                </button>
              </div>

              <div class="text-center p-t-136">
                <a class="txt2" href="#"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
